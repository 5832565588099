import { formatters, TFunction } from "@equiem/web-ng-lib";
import {
  ProductDealListingFragment,
  ProductPlainListingFragment,
} from "../../../generated/gateway-client";

export const nextAvailableString = (
  product: ProductPlainListingFragment | ProductDealListingFragment,
  t: TFunction,
  language: string,
) => {
  const nextAvailable = product.availability.nextAvailable;

  return nextAvailable != null && !product.availability.isAvailable
    ? `. ${t("main.nextAvailable")} ${formatters.dateshort(
        nextAvailable,
        language,
      )}, ${formatters.timeshort(nextAvailable, language)}`
    : "";
};
