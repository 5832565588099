import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { ProductPlainListingFragment } from "../../../generated/gateway-client";
import { PriceFormat } from "../../PriceFormat";
import { LimitedAvailabilityTag } from "./LimitedAvailabilityTag";
import { nextAvailableString } from "./nextAvailableString";
import { ProductListing } from "./ProductListing";

interface Props {
  product: ProductPlainListingFragment;
  className?: string;
  metric?: {
    ns: string;
    nx: number;
  };
}

export const ListingPlain: React.FC<Props> = ({ metric, product, className = "" }) => {
  const { t, i18n } = useTranslation();

  const metricExt = metric == null ? "" : `?ns=${metric.ns}&nx=${metric.nx}`;

  const next = nextAvailableString(product, t, i18n.language);

  return (
    <ProductListing
      name={product.name}
      image={product.image}
      available={product.available && !product.outOfStock}
      outOfStock={product.outOfStock}
      description={product.description}
      className={className}
      url={{
        as: `/store/product/${product.uuid}${metricExt}`,
        href: "/store/product/[uuid]",
      }}
      suffix={product.availability.limitedAvailability ? <LimitedAvailabilityTag top="1px" left="1px" /> : undefined}
    >
      <div className="price mt-auto">
        <PriceFormat price={product.unitPrice} freeTxt={t("main.free")} />
        {product.availability.limitedAvailability ? <span>{" "}({t("main.restrictedAvailability")}{next})</span> : null}
      </div>
    </ProductListing>
  );
}
