import { useTranslation } from "@equiem/web-ng-lib";
import React from "react";
import { ProductDealListingFragment } from "../../../generated/gateway-client";
import { DealTag } from "./DealTag";
import { nextAvailableString } from "./nextAvailableString";
import { ProductListing } from "./ProductListing";

interface Props {
  product: ProductDealListingFragment;
  className?: string;
  metric?: {
    ns: string;
    nx: number;
  };
}

export const ListingDeal: React.FC<Props> = ({ metric, product, className = "" }) => {
  const { t, i18n } = useTranslation();

  const metricExt = metric == null ? "" : `?ns=${metric.ns}&nx=${metric.nx}`;

  const next = nextAvailableString(product, t, i18n.language);

  return (
    <ProductListing
      className={className}
      name={product.name}
      image={product.image}
      available={product.availability.isAvailable}
      suffix={product.showOverlay ? <DealTag top="1px" left="1px" /> : undefined}
      description={product.description}
      url={{
        as: `/store/deal/${product.uuid}${metricExt}`,
        href: "/store/deal/[uuid]",
      }}
    >
      <div className="price mt-auto">
        <div className="deal mt-auto">{product.showOverlay ? `${t("main.deal")} ` : ""}{product.availability.limitedAvailability ? <span>({t("main.restrictedAvailability")}{next})</span> : null}</div>
      </div>
    </ProductListing>
  );
}
