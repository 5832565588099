import { Theme } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { CardImage } from "./CardImage";

interface Props {
  className?: string;
  headHeight?: string;
  bodyHeight?: string;
  orientation?: "portrait" | "landscape";
}

export const SkeletonCard: React.FC<Props> = ({
  className = "",
  headHeight = "160px",
  bodyHeight = "auto",
  orientation = "portrait",
}) => {
  const { breakpoints } = useContext(Theme);

  return (
    <div className={`skeleton-card d-flex rounded overflow-hidden ${orientation} ${className}`}>
      <CardImage placeholder={true} height={headHeight} />
      <div className="body p-3 pb-5 text-left overflow-hidden flex-grow-1">
        <div className="line shimmer"></div>
        <div className="line shimmer"></div>
        <div className="line shimmer"></div>
        <div className="line shimmer"></div>
        <div className="line shimmer"></div>
      </div>
      <style jsx>{`
      .skeleton-card {
        min-height:200px;
        background: #fff;
        border: 1px solid #eee;
        flex-direction: column;
      }
      .body {
        line-height: 1;
        height: ${bodyHeight};
      }
      .line {
        display: inline-block;
        padding: .25rem;
      }
      .line:nth-child(1) { width: 70%; margin: 0 0 .5rem 0; padding: .5rem }
      .line:nth-child(2) { width: 90% }
      .line:nth-child(3) { width: 80% }
      .line:nth-child(4) { width: 85% }
      .line:nth-child(5) { width: 55% }
      @media (min-width: ${breakpoints.sm}px) {
        .landscape {
          flex-direction: row;
          min-height: 0;
        }
        .landscape .body {
          padding-bottom: 0 !important;
        }
        .landscape .line { padding: .5rem; margin: .5rem 0 }
        .landscape .line:nth-child(1) { width: 70% }
        .landscape .line:nth-child(2) { width: 90% }
        .landscape .line:nth-child(3) { width: 80% }
        .landscape .line:nth-child(4) { display: none }
        .landscape .line:nth-child(5) { display: none }
      }
    `}</style>
    </div>
  );
};
