import React from "react";
import { BsClock } from "react-icons/bs";
import { ImageTag } from "./ImageTag";

interface Props {
  top?: string;
  left?: string;
}

export const LimitedAvailabilityTag: React.FC<Props> = ({ top = "0", left = "0" }) => (
  <ImageTag top={top} left={left}>
    <BsClock size="28px" />
  </ImageTag>
);
