import { useTranslation } from "@equiem/web-ng-lib";
import { Button, EscapedHtmlDiv, Site, stringNotEmpty } from "@equiem/web-ng-lib";
import color from "color";
import React, { useCallback, useContext, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AiFillTag } from "react-icons/ai";
import { RiFileCopyLine } from "react-icons/ri";
import { PromotedHomeDiscountsQuery } from "../generated/gateway-client";

interface Props {
  className?: string;
  discount: NonNullable<PromotedHomeDiscountsQuery["promotedHomeDiscounts"]>[0];
}

export const DiscountCard: React.FC<Props> = ({ className, discount }) => {
  const { t } = useTranslation();

  const site = useContext(Site);
  const background = color(site.primaryColour).alpha(0.15).rgb().string();
  const backgroundHover = color(site.primaryColour).alpha(0.25).rgb().string();

  const [copied, setCopied] = useState(false);
  const termExist = stringNotEmpty(discount.userTermsAndConditions);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const copy = useCallback(async () => {
    await navigator.clipboard.writeText(discount.code);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [discount]);

  return (
    <>
      <Container className={`discount-card p-3 rounded ${className}`}>
        <Row>
          <Col md="6" lg="7" xl="8" className="pb-2 pb-md-0 d-flex align-items-center">
            <div className="icon-discount rounded-circle d-flex justify-content-center align-items-center text-center d-none d-md-block">
              <AiFillTag />
            </div>
            <div className="pl-4">
              <div className="h5 mb-0 title font-weight-bold">{discount.promotion.title}</div>
              <div className="subtitle"><small>{discount.promotion.subtitle}</small></div>
              {termExist ? <button className="btn btn-link p-0 text-dark cursor-pointer" onClick={handleShow}><u><small>{t("main.termsAndConditionsApply")}</small></u></button> : null}
            </div>
          </Col>
          <Col md="6" lg="5" xl="4" className="d-flex font-weight-bold align-items-center flex-row-reverse">
            <div className="coupon-code-cont rounded">
              <small className="d-inline-block font-weight-bold px-1 px-sm-2 px-md-3 text-site">{discount.code}</small>
              <Button variant="solid" className="h-100" roundedRadius="0 4px 4px 0" onClick={copy}><small>{copied ? t("main.copied") : t("main.copyCode")}</small> <RiFileCopyLine className="cursor-pointer ml-1" /></Button>
            </div>
            <small className="mr-2 font-weight-bold d-none d-sm-block">{t("main.useCode")}</small>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("main.termsAndConditions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EscapedHtmlDiv className="discount-terms" html={discount.userTermsAndConditions ?? ""} />
        </Modal.Body>
      </Modal>
      <style jsx>{`
        :global(.discount-card) {
          background: ${background};
          transition: .25s ease-in-out;
        }
        :global(.discount-card:hover) {
          background: ${backgroundHover};
        }
        :global(.discount-terms ul),
        :global(.discount-terms ol) {
          margin-bottom: 0 !important;
        }
        .icon-discount {
          color: ${site.primaryColour};
          background: #fff;
          width: 52px;
          height: 52px;
          font-size: 28px;
          padding-top: 8px;
        }
        .coupon-code-cont {
          background: #fff;
        }
      `}</style>
    </>
  );
}
