import { useTranslation } from "@equiem/web-ng-lib";
import { stringNotEmpty } from "@equiem/web-ng-lib";
import { truncate } from "lodash";
import React from "react";
import { Card } from "../../Card";
import { CardImage } from "../../CardImage";

interface Props {
  name: string;
  image?: string | null;
  available?: boolean;
  outOfStock?: boolean;
  description?: string | null;
  className?: string;
  suffix?: JSX.Element;
  url: {
    href: string;
    as: string;
  }
  children?: React.ReactNode;
}

export const ProductListing: React.FC<Props> = ({ name, outOfStock = false, available = true, description, image, className = "", children, url, suffix }) => {
  const { t } = useTranslation();

  const itsAvailable = available && !outOfStock;
  const availableClass = itsAvailable ? "available" : "not-available";
  const desc = stringNotEmpty(description) ? (
    <div className="text-pre-line pb-3">
      {truncate(description, { length: 150 })}
    </div>
  ) : null;

  return (
    <div className="position-relative h-100">
      <Card
        img={<CardImage url={image} overlay={outOfStock ? (<span className="h5">{t("main.soldOut")}</span>) : undefined} />}
        href={itsAvailable ? url.href : undefined}
        as={itsAvailable ? url.as : undefined}
        title={name}
        className={`vendor-product h-100 ${availableClass} ${className ?? ""}`}
      >
        {desc}
        {children}
      </Card>
      {suffix}
      <style jsx>{`
        :global(.vendor-product.not-available) {
          cursor: not-allowed;
          opacity: 0.7;
        }
      `}</style>
    </div>
  )
}
