import { ThemeContext } from "@equiem/web-ng-lib";
import { times } from "lodash";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SkeletonCard } from "./SkeletonCard";

interface Props {
  className?: string;
  breakpoints?: ThemeContext["breakpoints"];
  headHeight?: string;
  bodyHeight?: string;
}

const displayClass = (
  cardIndex: number,
  breaks: ThemeContext["breakpoints"],
) => (
  cardIndex < breaks.xs ? "" :
  cardIndex < breaks.sm ? "d-none d-sm-block" :
  cardIndex < breaks.md ? "d-none d-md-block" :
  cardIndex < breaks.lg ? "d-none d-lg-block" :
  cardIndex < breaks.xl ? "d-none d-xl-block" :
  ""
);

export const SkeletonCards: React.FC<Props> = ({
  className = "",
  breakpoints = { xs: 1, sm: 2, md: 3, lg: 4, xl: 4 },
  headHeight,
  bodyHeight,
}) => (
  <div className={`skeleton-cards ${className}`}>
    <Container className="p-0">
      <Row>
        { times(breakpoints.xl, (i) => (
          <Col key={i} className={displayClass(i, breakpoints)}>
            <SkeletonCard headHeight={headHeight} bodyHeight={bodyHeight} orientation="portrait" />
          </Col>
        )) }
      </Row>
    </Container>
  </div>
);
