import React from "react";
import { BsArrowRepeat } from "react-icons/bs";
import { ImageTag } from "./ImageTag";

interface Props {
  top?: string;
  left?: string;
}

export const SubscriptionTag: React.FC<Props> = ({ top = "0", left = "0" }) => (
  <ImageTag top={top} left={left}>
    <BsArrowRepeat size="30px" />
  </ImageTag>
);
